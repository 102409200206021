<template>
  <div style="height: 60px"></div>
  <MainSize >
    <div class="swiper mySwiper">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(item,index) in imgList1" :key="index">
          <img :src="item">
        </div>
      </div>
    </div>
  </MainSize>
  <MainSize :height="main1Height">
    <div id="main-1-1-conten" style="width: 100%;height: 100%;position: relative" class="article main-1-1">
      <img :src="imgList2" >
      <div  class="main-1-1-conten" >
        <div class="one">
          <div class="xz main-1-1-conten-title">{{ item2.title }}</div>
          <div class="xz main-1-1-conten-ftitle">{{ item2.f_title }}</div>
          <div class="main-1-1-conten-msg">{{ item2.msg }}</div>
        </div>
        <div class="two">
          <img :src="item2.image" style="display: block;width: 100%;object-fit: cover">
        </div>
      </div>
    </div>
  </MainSize>
  <div class="main-1-2 ">
    <img class="article" src="../assets/logo.png" style="display: block;width: 96px">
    <div class="xz article" style="font-size: 20px;text-transform: uppercase;">21st Century US Sino Service</div>
    <div class="article" style="font-size: 32px;text-align: center;text-transform: uppercase;color: #ccad60;margin: 20px 0;font-family:'Lato'">Your American<br>Partner in Design</div>
    <div class="article msg12" style="margin-top: 50px;font-size: 0.12rem;line-height: 0.18rem;color: #333">21st Century was established in 2000, and offering variety construction services in terms of Architectural Design, Site Analysis, Feasibility Studies, Permit Application, General Contracting, Development, and Manufacturer, etc. We handle varous industrial, distribution, manufactiring, office, retail, multi-family projects.<br>We are a leader in providing value-added construction services to our customers by creating successful partnership with them throughout the construction preconstruction process and construction process. Our pledge is to establush lasting relationships with our customers by exceeding their expectations and gaining their trust through exceptional performance.</div>
  </div>
  <img class="article" src="../assets/image1/3.jpg" style="width: 100%;height: 460px;object-fit: cover;display: block">
  <div style="display: flex;justify-content: center;align-items: center;margin-top: 5%;flex-direction: column">
    <div class="xz article" style="font-size:20px;font-family:'Lato'">FEATURED PROJECTS</div>
    <div class="article xz" style="font-size:32px;margin-top: 10px;text-align: center;font-family:'Lato'">TURN YOUR DESIGN INTO REALITY</div>
    <div class="showFlex article" style="width: 8rem;flex-direction: row;justify-content: space-between;flex-wrap:wrap">
      <div class="main3-item-list" style="margin-top: 20px" @click="$emit('fclick',0)">
        <img src="../assets/image1/1.jpeg" class="zimage">
        <div class="shoplistMantle">
          <div>City Hyde Park Apartments</div>
          <div class="shoplistMantle_f">5105 S Harper Ave, Chicago, IL 60615</div>
        </div>
      </div>
      <div class="main3-item-list" style="margin-top: 20px" @click="$emit('fclick',1)">
        <img src="../assets/image1/21.png" class="zimage">
        <div class="shoplistMantle">
          <div>Solstice on the Park Apartments</div>
          <div class="shoplistMantle_f">1616 E 56th St, Chicago, IL 60636</div>
        </div>
      </div>
      <div class="main3-item-list" style="margin-top: 20px" @click="$emit('fclick',2)">
        <img src="../assets/image1/3.jpg" class="zimage">
        <div class="shoplistMantle">
          <div>St. Regis Chicago</div>
          <div class="shoplistMantle_f">363 E Wacker Dr, Chicago, IL 60601</div>
        </div>
      </div>
      <div class="main3-item-list" style="margin-top: 20px" @click="$emit('fclick',3)">
        <img src="../assets/image1/4.jpg" class="zimage">
        <div class="shoplistMantle">
          <div>700 N 4th St</div>
          <div class="shoplistMantle_f">Phoenix, AZ 85004</div>
        </div>
      </div>

      <div class="main3-item-list" style="margin-top: 20px" @click="$emit('fclick',4)">
        <img src="../assets/image1/5.jpg" class="zimage">
        <div class="shoplistMantle">
          <div>800 Broadway</div>
          <div class="shoplistMantle_f">San Diego, CA 92101</div>
        </div>
      </div>

      <div class="main3-item-list" style="margin-top: 20px" @click="$emit('fclick',5)">
        <img src="../assets/image1/6.jpeg" class="zimage">
        <div class="shoplistMantle">
          <div>Hampton Inn & Suites Chicago-Burr Ridge</div>
          <div class="shoplistMantle_f">100 Harvester Dr, Burr Ridge, IL 60527</div>
        </div>
      </div>
      <div class="main3-item-list" style="margin-top: 20px" @click="$emit('fclick',6)">
        <img src="../assets/image1/7.jpeg" class="zimage">
        <div class="shoplistMantle">
          <div>Four Points By Sheraton Milwaukee Airport</div>
          <div class="shoplistMantle_f">5311 S Howell Ave, Milwaukee, WI 53207</div>
        </div>
      </div>
      <div class="main3-item-list" style="margin-top: 20px" @click="$emit('fclick',7)">
        <img src="../assets/image1/8.jpeg" class="zimage">
        <div class="shoplistMantle">
          <div>Hyatt Place Chicago/O'Hare Airport</div>
          <div class="shoplistMantle_f">6810 Mannheim Road, Rosemont, IL 60018</div>
        </div>
      </div>
      <div class="main3-item-list" style="margin-top: 20px" @click="$emit('fclick',8)">
        <img src="../assets/ne/WX20221010-183212.png" class="zimage">
        <div class="shoplistMantle">
          <div>NEMA Chicago</div>
          <div class="shoplistMantle_f">1210 S Indiana Ave, Chicago, IL 60605</div>
        </div>
      </div>
    </div>


    <div class="noneFlex tlphone article" style="flex-direction: column;align-items: center;margin-top: 30px">
      <div class="main3-item-list" style="width: 7rem;height: 3rem;position: relative;margin-bottom: 20px" @click="$emit('fclick',0)">
        <img src="../assets/image1/1.jpeg" class="zimage">
        <div class="shoplistMantle">
          <div>City Hyde Park Apartments</div>
          <div class="shoplistMantle_f">5105 S Harper Ave, Chicago, IL 60615</div>
        </div>
      </div>
      <div class="main3-item-list" style="width: 7rem;height: 3rem;position: relative;margin-bottom: 20px" @click="$emit('fclick',1)">
        <img src="../assets/image1/21.png" class="zimage">
        <div class="shoplistMantle">
          <div>Solstice on the Park Apartments</div>
          <div class="shoplistMantle_f">1616 E 56th St, Chicago, IL 60636</div>
        </div>
      </div>
      <div class="main3-item-list" style="width: 7rem;height: 3rem;position: relative;margin-bottom: 20px" @click="$emit('fclick',2)">
        <img src="../assets/image1/3.jpg" class="zimage">
        <div class="shoplistMantle">
          <div>St. Regis Chicago</div>
          <div class="shoplistMantle_f">363 E Wacker Dr, Chicago, IL 60601</div>
        </div>
      </div>
      <div class="main3-item-list" style="width: 7rem;height: 3rem;position: relative;margin-bottom: 20px" @click="$emit('fclick',3)">
        <img src="../assets/image1/4.jpg" class="zimage">
        <div class="shoplistMantle">
          <div>700 N 4th St</div>
          <div class="shoplistMantle_f">Phoenix, AZ 85004</div>
        </div>
      </div>

      <div class="main3-item-list" style="width: 7rem;height: 3rem;position: relative;margin-bottom: 20px" @click="$emit('fclick',4)">
        <img src="../assets/image1/5.jpg" class="zimage">
        <div class="shoplistMantle">
          <div>800 Broadway</div>
          <div class="shoplistMantle_f">San Diego, CA 92101</div>
        </div>
      </div>

      <div class="main3-item-list" style="width: 7rem;height: 3rem;position: relative;margin-bottom: 20px" @click="$emit('fclick',5)">
        <img src="../assets/image1/6.jpeg" class="zimage">
        <div class="shoplistMantle">
          <div>Hampton Inn & Suites Chicago-Burr Ridge</div>
          <div class="shoplistMantle_f">100 Harvester Dr, Burr Ridge, IL 60527</div>
        </div>
      </div>
      <div class="main3-item-list" style="width: 7rem;height: 3rem;position: relative;margin-bottom: 20px" @click="$emit('fclick',6)">
        <img src="../assets/image1/7.jpeg" class="zimage">
        <div class="shoplistMantle">
          <div>Four Points By Sheraton Milwaukee Airport</div>
          <div class="shoplistMantle_f">5311 S Howell Ave, Milwaukee, WI 53207</div>
        </div>
      </div>
      <div class="main3-item-list" style="width: 7rem;height: 3rem;position: relative;margin-bottom: 20px" @click="$emit('fclick',7)">
        <img src="../assets/image1/8.jpeg" class="zimage">
        <div class="shoplistMantle">
          <div>Hyatt Place Chicago/O'Hare Airport</div>
          <div class="shoplistMantle_f">6810 Mannheim Road, Rosemont, IL 60018</div>
        </div>
      </div>
      <div class="main3-item-list" style="width: 7rem;height: 3rem;position: relative;margin-bottom: 20px" @click="$emit('fclick',8)">
        <img src="../assets/ne/WX20221010-183212.png" class="zimage">
        <div class="shoplistMantle">
          <div>NEMA Chicago</div>
          <div class="shoplistMantle_f">1210 S Indiana Ave, Chicago, IL 60605</div>
        </div>
      </div>
    </div>
    <div class="article xz" style="font-size:32px;margin-top: 5%;font-family:'Lato'">PARTNERS WITH US</div>
    <div class="article" style="width: 8rem;display: flex;flex-direction: row;justify-content: space-between;margin-top: 10px;padding-top: 30px;flex-wrap:wrap;align-items: center">
      <img src="../assets/logos/1.png"  class="logoListItem" @click="openWin('https://www.amberleafhome.us/')">
      <img src="../assets/logos/1-2.png"  class="logoListItem" style="width: 1rem" @click="openWin('https://amcenterusa.com/am-center/am-center-21st-century-window-dressing.html')">
      <img src="../assets/logos/3.png" class="logoListItem" >
    </div>
    <div class="article xz" style="font-size:32px;margin-top: 5%;font-family:'Lato'">OUR LOYAL CLIENTS</div>
    <div class="article" style="width: 8rem;display: flex;flex-direction: row;justify-content: space-between;margin-top: 10px;padding-top: 30px;flex-wrap:wrap;align-items: center">
      <img src="../assets/logos/1-1.png" class="logoListItem" style="width: 1.3rem" @click="openWin('https://www.related.com/?utm_source=Googlekeywords&utm_medium=SEM&utm_campaign=RelatedSEM&gclid=Cj0KCQjwnP-ZBhDiARIsAH3FSRfxMeYAcny0aO3xWhqT9pRIkkX-a_RWuS-POpgDV890zlTLGO7Xw48aAux4EALw_wcB')">
      <img src="../assets/logos/2.png" class="logoListItem" style="width: 1.3rem" @click="openWin('https://www.google.com/search?q=studio+gang+architects&oq=Studio+Gang+Architects&aqs=chrome.0.0i355i512j46i199i465i512j46i175i199i512j0i22i30l6j0i15i22i30.1292j0j15&sourceid=chrome&ie=UTF-8\n')">
      <img src="../assets/logos/15.png" class="logoListItem" style="width: 1.3rem" @click="openWin('https://www.ca-ventures.com')">
      <img src="../assets/logos/image.png" class="logoListItem" style="width: 1.3rem" @click="openWin('https://www.crescentheights.com')">
      <img src="../assets/logos/6.png" class="logoListItem" style="width: 1.3rem" @click="openWin('https://www.lendlease.com/us/')">
    </div>

    <div class="article" style="width: 8rem;display: flex;justify-content: start;flex-direction: column;margin-top: 10%">

      <div class="xz" style="font-size: 20px;font-family:'Lato'">CONTACT US</div>
      <div style="font-size: 32px;line-height: 70px;color:#ccad60;font-family:'Lato' ">CONNECT WITH US</div>
      <div style="font-size: 20px;line-height: 30px;margin-bottom: 10px;">The strength and success of the company can be measured by many quality projects they have completed, on time, in budget, with consistent client satisfaction. 21st Century takes pride in its teamwork, collaboration methodology and capacity building initiatives.</div>
<!--      <div style="font-size: 20px;line-height: 28px;margin-top: 10px">With our new 165,000 sqft warehouse in the heart of Chicago, we have the capability to better serve our customer, offering you a choice of the finest materials, the craftsmanship and care that go into every product, and a truly collaborative partnership – everything you need to go out and build your own dreams.</div>-->
    </div>
    <div  class="article" style="width: 8rem;display: flex;flex-direction: row;margin-top: 10px;margin-bottom: 50px;color: #ccad60;">
      <div style="width: 4px;background:#ccad60;align-self: stretch;"></div>
      <div style="display: flex;flex-direction: column;padding: 5px 10px">
        <div style="font-size: 28px;line-height: 50px">21st Century US Sino Service</div>
        <div style="font-size: 20px;line-height: 35px">Address: 500 W 18th St, Chicago, IL 60616</div>
        <div style="font-size: 20px;line-height: 35px">Contact: 312-808-9328</div>
        <div style="font-size: 20px;line-height: 35px">Email: 21us@21centuryservices.com</div>
        <div style="font-size: 20px;line-height: 35px">Website: www.21centuryservices.com</div>
      </div>
    </div>
  </div>
  <footerB />
</template>
<script>
import MainSize from './MainSize.vue'
import footerB from './footer.vue'
import imgList1_1 from "../assets/image1/1.jpeg";
import imgList1_2 from "../assets/image1/4.jpg";
import imgList1_3 from "../assets/image1/8.jpeg";
import imgList1_4 from "../assets/image1/11.jpg";
import img2 from "../assets/img.jpeg";
export default {
  data(){
    return{
      imgList1:[
        imgList1_1,
        imgList1_2,
        imgList1_3,
        imgList1_4
      ],
      imgList2 : imgList1_1,
      item2:{
        title:"ABOUT US",
        f_title:"DELIVERING EXCELLENCE IN ALL WE DO",
        msg:"21st Century is a sophisticated and experienced general contrating, construction management, and development firm. The company is led by a team of entrepreneurial and talented individuals that are bound together by a steadfast commitment to their core values of respect, integrity, courage, passion, humility and trust.\n" ,
        image:img2
      }
    }
  },
  components: {
    MainSize,
    footerB
  },
  name: "main-1",
  mounted() {
    window.addEventListener('resize', this.handleResize)
    window.addEventListener('scroll', this.handleScroll) // 监听页面滚动
    // eslint-disable-next-line no-undef
    var swiper = new Swiper(".mySwiper", {
      effect: 'fade',
      spaceBetween: 30,
      loop:true,
      centeredSlides: true,
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
      },
    });
    console.log(swiper)
  },
  methods:{
    openWin(text){
      window.location.href = text;
    },
    handleResize(){
      this.main1Height = document.getElementById("main-1-1-conten").offsetHeight;
      console.log(this.main1Height)
    },
    handleScroll () {
       let scrollTop =  document.documentElement.scrollTop ;
      scrollTop=scrollTop+document.documentElement.clientHeight;
      let articles =  document.getElementsByClassName("article");
      for (let i = 0 ;i<articles.length ;i++){
        if(scrollTop>articles[i].offsetTop&&articles[i].style.opacity!=1){
          articles[i].style.opacity = 1;
        }
      }
    }
  }
}
</script>

<style scoped>
.main-1-1 img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}
.main-1-1-conten{
  position:absolute;width: 100%;height:100%;top: 0;left: 0;overflow: hidden;display: flex;
  background: rgba(0,0,0,0.8);
  color: #fff;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.main-1-1-conten>.one{
  order: 0;
  width: 3rem;
  padding: 20px;
}
.main-1-1-conten>.two{
  padding: 20px;
  width: 3rem;
  order: 1;
}
.main-1-1-conten-title{
  font-family:"Lato" !important;

  font-size: 0.1rem;
  line-height: 20px;
}
.main-1-1-conten-ftitle{
  font-family:"Lato" !important;
  font-size: 0.16rem;
  line-height: 0.3rem;
}
.main-1-1-conten-msg{
  font-size: 0.12rem;
  line-height: 0.18rem;
}
.main-1-2{
  padding:10% 20% ;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.main3-item-list{
  background: #42b983;width: 2.5rem;height: 0.8rem;
  position: relative;
}
.shoplistMantle{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.8);
  opacity: 1;
  transition: all .5s;
  display: flex;
  font-size: 0.12rem;
  flex-direction: column;
  color: white;
  justify-content: center;
  align-items: center;
}
.tlphone .shoplistMantle{
  font-size: 0.3rem;

}
.tlphone .shoplistMantle_f{
  font-size: 0.20rem;
}
.shoplistMantle_f{
  font-size: 0.08rem;
}
.shoplistMantle:hover{
  opacity: 0;
}
.logoListItem{
  width: 2.6rem;
  height: fit-content;
  cursor: pointer;
}
.logoListItem:hover{
  opacity: .5;
}
@media (max-width: 990px){
  .main-1-1-conten-title{
    font-size: 0.3rem;
    line-height: 20px;
  }
  .main-1-1-conten-ftitle{
    font-size: 0.6rem;
    line-height: 0.9rem;
  }
  .main-1-1-conten-msg{
    font-size: 0.3rem;
    line-height: 0.6rem;
  }
  .logoListItem{
    cursor: pointer;
    width: 3rem !important;
    margin: 10px;
  }
  .main-1-1-conten{
    flex-direction: column;
  }
  .main-1-1-conten>.one{
    order: 1;
    width: 80%;
  }
  .main-1-1-conten>.two{
    order: 0;
    width: 80%;
  }
  .main-1-2{
    padding: 10% 15%;
  }
  .msg12{
    font-size: 0.3rem !important;
    line-height: 0.6rem !important;
  }
}
</style>