<template>
  <HeaderTitle style="position: fixed;width: 100%;top: 0;left: 0;z-index: 99" :xIndex="x_index" @indexClick="setIndex" @load="getLoad"/>
  <component  :is="componted" @fclick="fclick"/>
  <div v-if="vif" style="position: fixed;width: 100%;top: 0;left: 0;bottom: 0;right:0;z-index: 199;background: white;display: flex;flex-direction: column;justify-content: center;align-items: center">
    <img id="logo" class="hnode" src="./assets/logo.png" style="width: 3rem">
    <div id="text1" class="hnode" style="font-size: 0.4rem">21ST CENTURY US SINO SERVICE</div>
    <div id="text2" class="hnode" style="font-size: 0.25rem;color: #666">Your Partner in Design Creating Homes at Scale</div>
  </div>
  <div @click="emailType=true" style="background: #fff;width: 40px;height: 40px;border-radius: 60px;position: fixed;bottom: 20px;right: 20px;z-index: 101;text-align: center">
    <img src="./assets/email.png" style="width: 20px;display: block;margin: 10px">
  </div>
  <div v-if="emailType" @click="emailType=false" style="position: fixed;width: 100%;top: 0;left: 0;bottom: 0;right:0;z-index: 199;background: rgba(0,0,0,.3)">
    <div class="eJtFgm" @click.stop="" style="z-index: 102;width: 580px;transform: translateX(0%) translateZ(0px);">
      <div style="padding: 20px" >
        <div style="display: flex;justify-content: right;" >
          <div  @click.stop="emailType=false" style="width: 40px;height: 40px;background:rgba(0,0,0,.3);text-align: center;border-radius: 40px">
            <span class="material-symbols-outlined" style="color: white;line-height: 40px;margin: auto">
              clear
            </span>
          </div>
        </div>
        <div style="
    line-height: 1.32;
    font-family: Gotham,sans-serif;
    font-size: 32px;
    font-weight: bold;">
          Get in Touch
        </div>
        <div style="margin-top: 20px;display: block;
          font-family: Gotham,sans-serif;
          font-size: 16px;
          font-weight: bold;
          font-style: normal;
          color: rgb(0, 0, 0);">Name *</div>
        <input v-model="name" placeholder="John Doe" style="width: 100%;font-size: 15px;line-height: 20px;background:none;outline:none;border:none;margin-top: 10px;padding: 10px 0">
        <hr/>
        <div style="margin-top: 20px;display: block;
          font-family: Gotham,sans-serif;
          font-size: 16px;
          font-weight: bold;
          font-style: normal;
          color: rgb(0, 0, 0);">Company Name</div>
        <input v-model="cName" placeholder="" style="width: 100%;font-size: 15px;line-height: 20px;background:none;outline:none;border:none;margin-top: 10px;padding: 10px 0">
        <hr/>
        <div style="margin-top: 20px;display: block;
          font-family: Gotham,sans-serif;
          font-size: 16px;
          font-weight: bold;
          font-style: normal;
          color: rgb(0, 0, 0);">Email *</div>
        <input v-model="email" placeholder="exmaple@domain.com" style="width: 100%;font-size: 15px;line-height: 20px;background:none;outline:none;border:none;margin-top: 10px;padding: 10px 0">
        <hr/>
        <div style="margin-top: 20px;display: block;
          font-family: Gotham,sans-serif;
          font-size: 16px;
          font-weight: bold;
          font-style: normal;
          color: rgb(0, 0, 0);">Contact Number</div>
        <input v-model="CN" placeholder="" style="width: 100%;font-size: 15px;line-height: 20px;background:none;outline:none;border:none;margin-top: 10px;padding: 10px 0">
        <hr/>
        <div style="margin-top: 20px;display: block;
          font-family: Gotham,sans-serif;
          font-size: 16px;
          font-weight: bold;
          font-style: normal;
          color: rgb(0, 0, 0);">How can we help you? *</div>
        <input v-model="msg" placeholder="Your questions and comments" style="width: 100%;font-size: 15px;line-height: 20px;background:none;outline:none;border:none;margin-top: 10px;padding: 10px 0">
        <hr/>
        <div style="display: flex;justify-content: center">
          <div style="color: white;background: #333333;padding: 9px 21px;line-height: 1.32;border-radius: 3px;margin-top: 20px;
    cursor: pointer;
    font-weight: bold;
    font-size: 16px;" @click="eMail">Send</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderTitle from './components/HeaderTitle.vue'

import list1 from '@/components/list/list1'
import list2 from '@/components/list/list2'
import list3 from '@/components/list/list3'
import list4 from '@/components/list/list4'
import list5 from '@/components/list/list5'
import list6 from '@/components/list/list6'
import list7 from '@/components/list/list7'
import list8 from '@/components/list/list8'
import list9 from '@/components/list/list9'
import axios from "axios";
export default {
  name: 'App',
  components: {
    HeaderTitle
  },
  data(){
    return {
      emailType:false,
      vif:true,
      x_index:0,
      componted:null,
      openList:[
          list1,
          list2,
          list3,
          list4,
          list5,
          list6,
          list7,
          list8,
          list9
      ]
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll) // 监听页面滚动
    this.$nextTick(()=>{
      this.handleScroll();
      document.getElementById("logo").style.opacity = 1;
      setTimeout(()=>{
        document.getElementById("text1").style.opacity = 1;
        setTimeout(()=>{
          document.getElementById("text2").style.opacity = 1;
          setTimeout(()=>{
            this.vif = false;
          },2000);
        },1000);
      },1000);
    })

  },
  methods:{
    eMail(){
      if(!this.name){
        alert("Name is required");
        return;
      }
      if(!this.email){
        alert("Email is required");
        return;
      }
      if(!this.msg){
        alert("Help is required");
        return;
      }
      if(!this.cName){
        this.cName = "Null"
      }
      if(!this.CN){
        this.CN = "Null"
      }
      let text = `name:${this.name}<br/>
                  Company Name:${this.cName}<br/>
                  Email:${this.email}<br/>
                  Contact Number:${this.CN}<br/>
                  help:${this.msg}
                `
      axios.post("https://mioposnodeapi.miopos.us/daily_setEmailText",{
        "email": "21us@21centuryservices.com",
        "msg": text
      }).then((item)=>{
        console.log(item)
      })
      console.log(this.name,this.cName,this.email,this.CN,this.msg)
    },
    fclick(index){
      this.componted = this.openList[index];
    },
    handleScroll () {
      let scrollTop =  document.documentElement.scrollTop ;
      scrollTop=scrollTop+document.documentElement.clientHeight;
      let articles =  document.getElementsByClassName("article");
      for (let i = 0 ;i<articles.length ;i++){
        if(scrollTop>articles[i].offsetTop&&articles[i].style.opacity!=1){
          articles[i].style.opacity = 1;
        }
      }
    },
    setIndex(i,item){
      this.x_index = i;
      this.componted = item.componted;
    },
    getLoad(item){
      this.componted = item.componted;
    }
  }

}
</script>

<style>
.eJtFgm {
  background: white;
  position: fixed;
  bottom: 0px;
  max-width: calc(100% - 40px);
  height: 100%;
  overflow: auto;
  top: 0px;
  right: 0px;
}
html,body,div {
  margin: 0;
  padding: 0;
}
html,body{
  background: #f3f4f4;

}
.article{
  opacity: 0;
  transition: all 2s;
}
.hnode{
  opacity: 0;
  transition: all 2s;
}
.swiper,.swiper-wrapper {
  width: 100%;
  height: 100%;
}
.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.swiper-slide{
  display: block;
  width: 100%;
  height: 100%;
}
.showFlex{
  display: flex;
}
.noneFlex{
  display: none;
}
@media (max-width: 990px){
  .showFlex{
    display: none;
  }
  .noneFlex{
    display: flex;
  }
}
.xz{
  background-image: url("./assets/text_bg.jpeg");
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.zimage{
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}
</style>
